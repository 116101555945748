<template>
  <v-container
    id="google-maps"
    fluid
    tag="section"
  >
    <v-row
      class="mt-12"
      justify="end"
    >
      <!--  -->
      <v-col
        cols="12"
        md="2"
        lg="2"
        offset-md="6"
        offset-lg="6"
      >
        <v-btn
          to="/play/create"
          left
          width="100%"
          color="primary"
        >
          <span class="mr-1">
            <v-icon light>mdi-pencil-plus</v-icon>
          </span>
          Upload
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-12">
      <v-col
        v-if="live.data !== null"
        cols="12"
        sm="12"
        lg="6"
      >
        <base-material-card
          color="orange"
          :title="live.data.detail.title.substr(0, 30) + '....'"
          class="px-5 py-3"
          icon="mdi-access-point"
        >
          <v-row justify="space-around">
            <v-card
              width="400"
              height="400"
            >
              <div width="100%">
                <div class="embed-responsive">
                  <iframe
                    loading="lazy"
                    width="400"
                    height="300"
                    sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation allow-presentation"
                    class="embed-responsive-item"
                    allowfullscreen
                    :src="
                      'https://www.youtube.com/embed/' +
                        live.data.path +
                        '?feature=oembed'
                    "
                  />
                </div>
              </div>
              <div class="d-flex flex-no-wrap mt-6">
                <div class="d-flex flex-column justify-center ml-6">
                  <v-btn
                    x-large
                    max-width="100"
                    width="100"
                    color="primary"
                    text
                    :to="'/play/' + live.data.id + '/edit/live'"
                  >
                    Perbaharui
                  </v-btn>
                </div>
                <div class="d-flex flex-column justify-center ml-6">
                  <v-btn
                    x-large
                    max-width="100"
                    width="100"
                    color="primary"
                    text
                    @click="openDialogp(live.data.id, live.data.detail.title)"
                  >
                    Jadikan Playlist
                  </v-btn>
                </div>
                <div class="d-flex flex-column justify-center ml-6">
                  <v-btn
                    x-large
                    max-width="100"
                    width="100"
                    color="primary"
                    text
                    @click="openDialog(live.data.id, live.data.detail.title)"
                  >
                    Hapus
                  </v-btn>
                </div>
              </div>
            </v-card>
          </v-row>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        sm="12"
        lg="6"
      >
        <base-material-card
          color="success"
          title="Preview Radio Stream"
          icon="mdi-headphones"
          class="px-5 py-3"
        >
          <v-row justify="space-around">
            <v-card width="400">
              <v-img
                height="200px"
                src="https://cdn.pixabay.com/photo/2020/07/12/07/47/bee-5396362_1280.jpg"
              />
              <hr>

              <v-btn
                x-large
                max-width="400"
                width="400"
                class=" mt-5 justify-center"
                color="success"
                text
              >
                Perbaharui
                <!-- <template v-slot:loader>
                  <span>Loading...</span>
                </template> -->
              </v-btn>
            </v-card>
          </v-row>
        </base-material-card>
      </v-col>
      <v-col
        v-for="(data, i) in play.data"
        :key="i"
        cols="12"
        sm="12"
        lg="6"
      >
        <base-material-card
          v-if="data !== null"
          color="red"
          :title="data.detail.title.substr(0, 30) + '...'"
          class="px-5 py-3"
          icon="mdi-youtube"
        >
          <v-row justify="center">
            <v-card
              width="400"
              height="400"
            >
              <div width="100%">
                <div class="embed-responsive">
                  <iframe
                    loading="lazy"
                    width="400"
                    height="300"
                    sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation allow-presentation"
                    class="embed-responsive-item"
                    allowfullscreen
                    :src="
                      'https://www.youtube.com/embed/' +
                        data.path +
                        '?feature=oembed'
                    "
                  />
                </div>
              </div>

              <div class="d-flex flex-no-wrap mt-6">
                <div class="d-flex flex-column justify-center ml-6">
                  <v-btn
                    x-large
                    max-width="100"
                    width="100"
                    color="primary"
                    text
                    :to="'/play/' + data.id + '/edit'"
                  >
                    Perbaharui
                  </v-btn>
                </div>

                <div class="d-flex flex-column justify-center ml-6">
                  <v-btn
                    x-large
                    max-width="100"
                    width="100"
                    color="primary"
                    text
                    @click="openDialogD(data.id, data.detail.title)"
                  >
                    Jadikan Live
                  </v-btn>
                </div>
                <div class="d-flex flex-column justify-center ml-6">
                  <v-btn
                    x-large
                    max-width="100"
                    width="100"
                    color="primary"
                    text
                    @click="openDialog(data.id, data.detail.title)"
                  >
                    Hapus
                  </v-btn>
                </div>
              </div>
            </v-card>
          </v-row>
        </base-material-card>
      </v-col>
      <v-col cols="12">
        <template>
          <v-dialog
            v-model="dialog.open"
            width="500"
            :retain-focus="false"
          >
            <v-card>
              <v-card-title primary-title>
                Confirmation
              </v-card-title>

              <v-card-text> Judul : "{{ dialog.title }}"? </v-card-text>
              <v-card-text class="text-center">
                Pilih Perintah
              </v-card-text>

              <v-divider />

              <v-card-actions>
                <v-btn
                  color="primary"
                  @click="dialog.open = false"
                >
                  Kembali
                </v-btn>
                <v-spacer />
                <v-btn
                  color="error"
                  @click="delPlaylist(dialog.id)"
                >
                  HAPUS
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-col>
      <v-col cols="12">
        <template>
          <v-dialog
            v-model="openD.open"
            width="500"
            :retain-focus="false"
          >
            <v-card>
              <v-card-title primary-title>
                Confirmation
              </v-card-title>

              <v-card-text>Judul : "{{ openD.title }}" </v-card-text>
              <v-card-text>
                Apakah Yakin Akan Mentransfer Vidio Ke Live ?
              </v-card-text>

              <v-divider />

              <v-card-actions>
                <v-btn
                  color="primary"
                  @click="openD.open = false"
                >
                  Kembali
                </v-btn>
                <v-spacer />
                <v-btn
                  color="info"
                  @click="tolive(openD.id)"
                >
                  Jadikan Live
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-col>
      <v-col cols="12">
        <template>
          <v-dialog
            v-model="openP.open"
            width="500"
            :retain-focus="false"
          >
            <v-card>
              <v-card-title primary-title>
                Confirmation
              </v-card-title>

              <v-card-text> Judul: "{{ openP.title }}"? </v-card-text>
              <v-card-text>
                Apakah Yakin Akan Mentransfer Vidio Ke Playlist ?
              </v-card-text>

              <v-divider />

              <v-card-actions>
                <v-btn
                  color="primary"
                  @click="openP.open = false"
                >
                  Kembali
                </v-btn>
                <v-spacer />
                <v-btn
                  color="info"
                  @click="toPlaylist(openP.id)"
                >
                  Ubah
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')
  export default {
    name: 'PlayCreate',
    data: () => ({
      play: {
        meta: {},
        data: null,
      },
      dialog: {
        open: false,
        id: 0,
        title: '',
      },
      openD: {
        open: false,
        id: 0,
        title: '',
      },
      openP: {
        open: false,
        id: 0,
        title: '',
      },
      live: {
        meta: {},
        data: null,
      },
    }),
    mounted () {
      this.getPlay()
      this.getLive()
    },
    methods: {
      delPlaylist (id) {
        axios.delete('/v1/media/' + id).then(response => {
          if (response.data.meta.status) {
            this.dialog.open = false
            this.$toast.error('Berhasil menghapus Playlist', {
              type: 'error',
              position: 'top-right',
              duration: 3000,
              dismissible: true,
            })
          }
          // update
          this.getPlay()
          this.getLive()
        })
      },
      openDialog (id, title) {
        this.dialog.id = id
        this.dialog.title = title
        this.dialog.open = true
      },
      openDialogD (id, title) {
        this.openD.open = true
        this.openD.title = title
        this.openD.id = id
      },
      openDialogp (id, title) {
        this.openP.open = true
        this.openP.title = title
        this.openP.id = id
      },
      toPlaylist (id) {
        axios.patch('/v1/media/' + id + '/playlist').then(response => {
          if (response.data.meta.status) {
            this.openP.open = false
            this.$toast.success('Berhasil Di Transfer Ke Playlist', {
              type: 'success',
              position: 'top-right',
              duration: 3000,
              dismissible: true,
            })
            // Update
            this.getPlay()
            this.getLive()
          }
        })
      },
      tolive (id) {
        axios.patch('/v1/media/' + id + '/live').then(response => {
          if (response.data.meta.status) {
            this.openD.open = false
            this.$toast.success('Berhasil Di Transfer Ke Live', {
              type: 'success',
              position: 'top-right',
              duration: 3000,
              dismissible: true,
            })
            // Update
            this.getPlay()
            this.getLive()
          }
        })
      },
      reloadPage () {
        window.location.reload()
      },
      setLive (data) {
        this.live = data
      },
      getLive () {
        axios.get('/v1/media/video/live').then(response => {
          if (response.data.meta.status) {
            this.setLive(response.data)
            this.openD.open = false
          } else {
            this.openD.open = false
            this.setLive({
              meta: {},
              data: null,
            })
          }
        })
      },
      setPlay (data) {
        this.play = data
      },
      getPlay () {
        axios
          .get('/v1/media/video?status=public&type=playlist&sort=-id&offset=10')
          .then(response => {
            console.log(response.data)
            if (response.data.meta.status) {
              this.setPlay(response.data)
            } else {
              this.setPlay({
                meta: {},
                data: null,
              })
              console.log(this.setPlay())
            }
          })
      },
    },
  }
</script>
